import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Fetch from "i18next-fetch-backend";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next/client";
import i18n from "./i18n";
import { Provider } from "react-redux";
import { store } from "~/old-app/redux/index";

import * as Sentry from "@sentry/remix";

// Sentry.init({
//   dsn: window?.ENV?.SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration({
//       useEffect,
//       useLocation,
//       useMatches,
//     }),
//     // Replay is only available in the client
//     Sentry.replayIntegration(),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for tracing.
//   // We recommend adjusting this value in production
//   // Learn more at
//   // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", window?.ENV?.BASE_BE_API],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   // Learn more at
//   // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

async function main() {
  if (typeof window.globalStore === "undefined") {
    window.globalStore = store;
  }
  let { defaultNS, fallbackLng, supportedLngs } = i18n;

  await i18next
    .use(initReactI18next)
    .use(Fetch)
    .use(I18nextBrowserLanguageDetector)
    .init({
      debug: false,
      defaultNS,
      fallbackLng,
      supportedLngs,
      ns: getInitialNamespaces(),
      detection: {
        order: ["htmlTag"],
        caches: [],
      },
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        {/* <StrictMode> */}
        <Provider store={window.globalStore}>
          <RemixBrowser />
        </Provider>
        {/* </StrictMode> */}
      </I18nextProvider>
    );
  });
}

main().catch((error) => console.error(error));
